import { FuseNavigationItem } from '@fuse/components/navigation';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { NavigationActions } from './navigation.actions';

export interface NavigationEntity extends FuseNavigationItem {}

export interface NavigationState extends EntityState<NavigationEntity> {
  selectedId: string | null;
}

export const navigationAdapter: EntityAdapter<NavigationEntity> = createEntityAdapter<NavigationEntity>({
  selectId: (navigation: NavigationEntity) => navigation.id
});

export const initialState: NavigationState = navigationAdapter.getInitialState({
  selectedId: ''
});

export const navigationFeature = createFeature({
  name: 'navigationState',
  reducer: createReducer(
    initialState,
    on(NavigationActions.setData, (state, { navigation }) => navigationAdapter.setAll(navigation, { ...state, loaded: true }))
  ),
  extraSelectors: ({ selectNavigationStateState }) => ({
    selectDashboard: navigationAdapter.getSelectors(selectNavigationStateState).selectAll,
    selectNavigation: createSelector(navigationAdapter.getSelectors(selectNavigationStateState).selectAll, items => {
      items.unshift({
        id: 'dashboard',
        title: 'Dashboard',
        type: 'basic',
        icon: 'heroicons_outline:home',
        link: '/dashboard'
      });
      return {
        default: items,
        compact: formatCompactNavigation(items),
        futuristic: formatFuturisticNavigation(items),
        horizontal: formatHorizontalNavigation(items)
      };
    })
  })
});

function formatCompactNavigation(defaultNavigation: FuseNavigationItem[]): FuseNavigationItem[] {
  return defaultNavigation.map(item => ({
    ...item,
    type: item.children ? 'aside' : item.type
  })) as FuseNavigationItem[];
}

function formatFuturisticNavigation(defaultNavigation: FuseNavigationItem[]): FuseNavigationItem[] {
  return defaultNavigation.map(item => item) as FuseNavigationItem[];
}

function formatHorizontalNavigation(defaultNavigation: FuseNavigationItem[]): FuseNavigationItem[] {
  return defaultNavigation.map(item => ({
    ...item,
    type: item.children ? 'aside' : item.type
  })) as FuseNavigationItem[];
}

const { name, reducer, ...selectors } = navigationFeature;
export const fromNavigation = selectors;
