import { FuseNavigationItem } from '@fuse/components/navigation';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

const navigation = createActionGroup({
  source: 'Navigation',
  events: {
    initData: emptyProps(),
    setData: props<{ navigation: FuseNavigationItem[] }>(),
    registerModuleAccess: props<{ idModulo: string; sede: string; sedeType: number }>()
  }
});

const navigationApi = createActionGroup({
  source: 'Navigation API',
  events: {
    loadSuccess: props<{ navigation: FuseNavigationItem[] }>(),
    loadFailure: props<{ error: any }>(),
    registerModuleAccessSuccess: emptyProps()
  }
});

export const NavigationActions = {
  ...navigation,
  ...navigationApi
};
